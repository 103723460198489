import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, JsonPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

//Pipes
import { GetTextPipe } from '../pipes/getText.pipe';
import { GetSumPipe } from '../pipes/getSum.pipe';
import { GetTextMultiPipe } from '../pipes/getTextMulti.pipe';
import { SafePipe } from '../pipes/safe.pipe';
import { GetFractionPipe } from '../pipes/get-fraction.pipe';
import { TitleCasePipe } from '../pipes/title-case/title-case.pipe';

//AIMS components
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { LookupComponent } from '../components/lookup/lookup.component';
import { LookupPopupComponent } from '../components/lookup/lookupPopup.component';
import { AimsSideMenuComponent, AimsSideMenuLinkComponent, AimsSideMenuHeaderComponent, ExternalLinkDirective } from '../components/aims-side-menu/aimsSideMenu.component';
import { AimsPersistantSiteComponent } from '../components/aims-persistant-site/aims-persistant-site.component';
import { AimsFormComponent, AimsFormControlComponent } from '../components/aims-forms/aimsForm.component';
import { AimsGridUploadComponent, AimsGridUploadDisplayComponent } from '../components/aims-grid-upload/aims-grid-upload.component';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';
import { FileUploadComponent2 } from '../components/file-upload2/file-upload2.component';
import { UploadProgressComponent } from '../components/upload-progress/upload-progress.component';
import { NewGridFileUploadComponent } from '../components/new-grid-file-upload/new-grid-file-upload.component';
import { AimsGridFileDisplayComponent } from '../components/aims-grid-file-display/aims-grid-file-display.component';
import { AimsPauseComponent } from '../components/aims-pause/aims-pause.component';
 
import { ConfigLocationsComponent } from '../components/config-locations/config-locations.component';
import { ConfigSubLocationsComponent } from '../components/config-sub-locations/config-sub-locations.component';

// //AIMS filters

// //AIMS directives
import { StandardViewWorkOrdersComponent } from '../containers/assetMgmt/workorders/standard-view-workorders/standard-view-workorders.component';
import { ViewWorkOrdersComponent } from '../containers/assetMgmt/workorders/view-work-orders/view-work-orders.component';

//Text Editor
import { QuillModule, QuillEditorComponent } from 'ngx-quill';
import { CanDeactivateGuard } from '../guards/can-deactivate-guard.service';

//Help icon
import { AimsHelpComponent } from '../components/aims-help/aims-help.component';

// Material
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AimsCommonTableModule, AimsCommonButtonMiniModule, AimsCommonButtonModule,AimsCommonLabelTooltipModule } from '@aims/common-components';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
//import { MaterialModule } from './material.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { AimsNumericComponent } from '../components/aims-numeric/aims-numeric.component';
import { FilterPipe } from '../pipes/filter.pipe';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

//Thirdpart components
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import {
           NgxMatDatetimePickerModule,
           NgxMatNativeDateModule,
           NgxMatTimepickerModule
  } from '@angular-material-components/datetime-picker';
import { ConfigManufacturersComponent } from '../components/config-manufacturers/config-manufacturers.component';
import { ConfigOwnersComponent } from '../components/config-owners/config-owners.component';
import { AimsImportComponent } from '../components/aims-import/aims-import.component';
import { AimsGridSelectorComponent } from '../components/aims-grid-selector/aims-grid-selector.component';


@NgModule({
  declarations: [
    FilterPipe,
    GetTextPipe,
    GetTextMultiPipe,
    GetSumPipe,
    SafePipe,
    GetFractionPipe,
    SpinnerComponent,
    LookupComponent,
    LookupPopupComponent,
    AimsSideMenuComponent,
    AimsSideMenuLinkComponent,
    AimsSideMenuHeaderComponent,
    AimsPersistantSiteComponent,
    AimsFormComponent,
    AimsFormControlComponent,
    ExternalLinkDirective,
    StandardViewWorkOrdersComponent,
    ViewWorkOrdersComponent,
    AimsGridUploadComponent,
    AimsGridUploadDisplayComponent,
    AimsHelpComponent,
    FileUploadComponent,
    FileUploadComponent2,
    NewGridFileUploadComponent,
    AimsGridFileDisplayComponent,
    AimsNumericComponent,
    TitleCasePipe,
    ConfigLocationsComponent,
    ConfigSubLocationsComponent,
    ConfigManufacturersComponent,
    ConfigOwnersComponent,
    UploadProgressComponent,
    AimsImportComponent,
    AimsGridSelectorComponent,
    AimsPauseComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    QuillModule,
    MatSelectModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatButtonToggleModule,
    AimsCommonButtonModule,
    AimsCommonButtonMiniModule,
    AimsCommonLabelTooltipModule,
    MatCheckboxModule,
    MatTabsModule,
    MatTooltipModule,
    AimsCommonTableModule,
    MatProgressBarModule,
    MatRadioModule,
    MatMenuModule,
    //MaterialModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatListModule,
    MatExpansionModule
  ],
  exports: [
    FilterPipe,
    GetTextPipe,
    GetTextMultiPipe,
    GetSumPipe,
    SafePipe,
    GetFractionPipe,
    SpinnerComponent,
    LookupComponent,
    LookupPopupComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DatePipe,
    JsonPipe,
    AimsSideMenuComponent,
    AimsSideMenuLinkComponent,
    AimsSideMenuHeaderComponent,
    AimsPersistantSiteComponent,
    AimsFormComponent,
    AimsFormControlComponent,
    QuillModule,
    QuillEditorComponent,
    ExternalLinkDirective,
    StandardViewWorkOrdersComponent,
    ViewWorkOrdersComponent,
    AimsGridUploadComponent,
    AimsGridUploadDisplayComponent,
    AimsHelpComponent,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatButtonToggleModule,
    AimsCommonTableModule,
    AimsCommonButtonModule,
    AimsCommonButtonMiniModule,
    AimsCommonLabelTooltipModule,
    MatCheckboxModule,
    MatTabsModule,
    MatCardModule,
    MatTooltipModule,
    MatMenuModule,
    AimsCommonTableModule,
    MatProgressBarModule,
    MatRadioModule,
    FileUploadComponent,
    FileUploadComponent2,
    NewGridFileUploadComponent,
    AimsGridFileDisplayComponent,
    AimsNumericComponent,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatListModule,
    MatExpansionModule,
    TitleCasePipe,
    ConfigLocationsComponent,
    ConfigSubLocationsComponent,
    ConfigManufacturersComponent,
    ConfigOwnersComponent,
    UploadProgressComponent,
    AimsImportComponent,
    AimsGridSelectorComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    AimsPauseComponent
  ],
  providers: [
    CanDeactivateGuard,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {}},
    provideNgxMask()
  ]
})
export class SharedModule { }
